@use './functions' as func;

@mixin font-face($name, $nameFile, $weight, $style: normal, $display: swap) {
	@font-face {
		font-family: $name;
		src: url('../fonts' + '/' + $name + '/' + $nameFile + '.ttf')
			format('truetype');
		font-weight: $weight;
		font-style: $style;
		font-display: $display;
	}
}

@mixin set-theme($theme) {
	@each $key, $map in $themes {
		@each $prop, $color in $map {
			@if $prop == $theme {
				--#{$key}: #{$color};
				--#{$key}--rgb: #{func.hexToRGB($color)};
			}
		}
	}
}

@mixin text-14 {
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

@mixin flex-column {
	display: flex;
	flex-direction: column;
}

@mixin flex-row {
	display: flex;
	flex-direction: row;
}

@mixin transition(
	$property: all,
	$duration: 300ms,
	$timing-function: ease-in-out
) {
	transition-property: $property;
	transition-duration: $duration;
	transition-timing-function: $timing-function;
}
