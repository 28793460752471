@use './functions' as func;

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;

	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
		border-radius: 8px;
	}

	&::-webkit-scrollbar-thumb {
		background: func.getColor(background-secondary);
		border-radius: 8px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: func.getColor(background-secondary-hovered);
	}
}

html {
	font-size: 16px;
	font-family: 'SourceSans3', 'sans-serif';

	@include set-theme(light);
}

html[theme='light'],
html[class='light'] {
	@include set-theme(light);
}

html[theme='dark'],
html[class='dark'] {
	@include set-theme(dark);
}

html,
body {
	height: 100vh;
	overscroll-behavior: none;
}

body {
	color: func.getColor(text-primary);
	background: func.getColor(background-surface);
	width: 100vw;
	overflow-x: hidden;
}

ol,
ul {
	list-style: none;
}

//input,
//button,
//select,
//textarea {
//	font-family: inherit;
//	color: inherit;
//}
