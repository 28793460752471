/* You can add global styles to this file, and also import other style files */
@import 'libs/ui/kit/src/lib/assets/styles/index.scss';

html[theme='light'] {
	@include set-theme(light);
}

html[theme='dark'] {
	@include set-theme(dark);
}
